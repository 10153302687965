import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Dropzone } from '@commonsku/styles';

import { closePopup } from '../actions/popup';
import { createUploadFile } from '../actions/file';
import { createAddTemp } from '../actions/temp';
import * as actions from '../actions/item';
import { createUpdate } from '../actions';

import { getFolderOptions } from '../selectors/dropdowns';

import { getImageSrc } from '../utils';
import { BASE_ZINDEX } from '../popup-factory';
import Img from './Img';

export const TBD_FILE_ID = '00000000-0000-0000-0000-000000000000';

class ArtworkLibrary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      folder_id: ''
    };
  }

  onClickFolder(folder_id) {
    this.setState({ folder_id });
  }

  render() {
    const { folders, files, onClosePopup, onUploadFile, onSelectFile, loading, item, onUpdateItem, index, popupClassName = 'reveal large' } = this.props;
    const { popupStyle = { display: 'block', zIndex: BASE_ZINDEX + index } } = this.props;
    return (
      <div id="artwork-library-modal" className={popupClassName} style={popupStyle} role="dialog">
        <div className="row popup-content">
          <div className="small-12 columns">
            <h3>Select Artwork</h3>
          </div>
          <div className="small-12 columns popup-size-limit">
            <div className="small-12 medium-3 columns" style={{ paddingLeft: '0px' }}>
              <div className="row" style={{ display: 'inline' }}>
                <h4 style={{ float: 'left' }}>Library</h4>
                <a className="button" style={{ float: 'right' }}>
                  <Dropzone onDrop={onUploadFile(this.state.folder_id)} style={{ border: 'none' }} multiple={false}>
                    <>
                      {loading ?
                        <div className="small-12 text-center"><br /><img src="/images/gears.gif" /><br /></div>
                        : 'Upload Image'}
                    </>
                  </Dropzone>
                </a>
              </div>
              <div className="row">
                <ul className="tabs vertical">
                  {folders.map(f =>
                    <li key={f.key} className={(f.key === this.state.folder_id ? 'is-active ' : '') + 'tabs-title'} onClick={e => this.onClickFolder(f.key)}>
                      <a aria-selected={f.key === this.state.folder_id}>
                        <i className="fi-folder">&nbsp;&nbsp;</i><span>{f.value}</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="small-12 medium-9 columns">
              <div className="row small-up-1 medium-up-2 large-up-3">
                {!this.state.folder_id ?
                  <div className="column">
                    <img className="thumbnail" style={{ height: '150px', marginTop: '1rem', marginBottom: '0' }} src="/images/404.png" alt="TBD" onClick={e => onSelectFile(TBD_FILE_ID)} />
                    <br />
                    <span>TBD</span>
                  </div> : undefined}
                {files.filter(f => f.folder_id === this.state.folder_id).map((f, i, m) =>
                  <div key={f.file_id} className="column">
                    <Img className="thumbnail" style={{ height: '150px', marginTop: '1rem', marginBottom: '0' }} src={getImageSrc(f, 'small')} alt={f.file_display_name} onClick={e => { item ? onUpdateItem(item.item_id, 'image_id', item.image_id)(f.file_id) : onSelectFile(f.file_id); }} />
                    <br />
                    <span style={{ wordWrap: 'break-word' }}>{f.file_display_name}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => { e.preventDefault(); onClosePopup(); }}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    folders: [{ key: '', value: 'Artwork' }].concat(getFolderOptions(state)),
    files: Object.values(state.entities.files).filter(f => f.active == 1 && f.parent_id === ownProps.account_id).sort((a, b) => b.date_created - a.date_created),
    loading: state.display.loading.upload_file
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const updateItem = createUpdate('item', {
    request: actions.UPDATE_ITEM_REQUEST,
    success: actions.UPDATE_ITEM_SUCCESS,
    failure: actions.UPDATE_ITEM_FAILURE,
    failure_message: 'Unable to update item'
  });
  const onClosePopup = ownProps.onClosePopup ? ownProps.onClosePopup : () => dispatch(closePopup());
  const onSelectFile = file_id => {
    if (ownProps.onSelectFile) {
      ownProps.onSelectFile(file_id);
    } else {
      dispatch(createAddTemp(ownProps.register, file_id));
    }
    onClosePopup();
  };
  return {
    onClosePopup,
    onUploadFile: folder_id => files => {
      dispatch(createUploadFile(ownProps.account_id, ownProps.account_type, files[0], folder_id));
    },
    onSelectFile,
    onUpdateItem: (item_id, field, previous_value) => value => {
      dispatch(updateItem(item_id, field, previous_value, value));
      onClosePopup();
    },
  };
};

const ConnectedArtworkLibrary = connect(mapStateToProps, mapDispatchToProps)(ArtworkLibrary);
export default ConnectedArtworkLibrary;
